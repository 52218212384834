// Import our custom CSS
import '../scss/styles.scss'
import '../scss/cv.css'
import '../../public/javascripts/cvscript.js'
import '@fortawesome/fontawesome-free/css/all.css'



import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'
/*import Util from 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown.js';
import 'bootstrap/js/dist/tab.js';
import 'bootstrap/js/dist/modal';*/
//import * as jquery from 'jquery'

console.log("Webpack Loaded");

// A $( document ).ready() block.
$( document ).ready(function() {
    console.log( "ready!" );

    //window.$('#exampleModal').modal('show')
    const myModalEl = document.querySelector('#contactmodal')
    const modal = new bootstrap.Modal(myModalEl) // initialized with defaults
    $('#contactbtn').click(function(e){
        modal.show();
    })

    $('#contactclose').click(function(e){
        modal.hide();
    })

    $('#sendMessage').click(function(e){
        modal.hide();
    })
    //window.$('#exampleModal').modal('show')

});