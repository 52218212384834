$(document).ready(function f ()  {

    const btnContact = document.getElementById("sendMessage")

    btnContact.addEventListener("click", function e() {
        
        const email = document.getElementById("emailc")
        const mess = document.getElementById("messagec")
      
      
            console.log("Author : " + email.value + " - Message : " + mess.value)  

            if(!(email.value == "") & !(mess.value == "")) {

                console.log("Sended")
                fetch('/sendform', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"sender": email.value, "content": mess.value })
                })
                .then(response => response.json())
                .then(response => console.log(JSON.stringify(response)))
            } 
        
       

    })

    

})
